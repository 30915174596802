<template>
  <div class="income">
    <div class="income-head">
      <div class="income-head-it">
        <span>{{item.type_text}}</span>
        <span>¥{{item.commision}}</span>
      </div>
      <div class="income-head-it">
        <span>{{item.created_time_text}}</span>
        <span>{{item.state_text}}</span>
      </div>
    </div>
    <div class="income-body">
      <div class="income-body-it">
        <span>奖金说明：</span>
        <span>{{item.remark}}</span>
      </div>
      <div class="income-body-it">
        <span>下 单 人 ：</span>
        <span>{{item.createdby_name}}</span>
      </div>
      <div class="income-body-it">
        <span>生效时间：</span>
        <span>{{item.effective_time_text}}</span>
      </div>
      <div class="income-body-it">
        <span>关联订单：</span>
        <span>{{item.order_sn}} <span class="copy" v-if="item.order_sn" @click="copy">复制</span></span>
      </div>
    </div>
  </div>
</template>
<script>
import { nextTick } from 'vue'
import {Toast} from 'vant'
import useClipboard from 'vue-clipboard3'
export default {
  props: {
    item: Object
  },
  setup(props) {
    const { toClipboard } = useClipboard()
    const copy = async () => {
      try {
      	//复制
        await toClipboard(props.item.order_sn)
        Toast('复制成功')
      } catch (e) {
      	//复制失败
        console.error(e)
      }
    }
    return {
      copy
    }
  }
}
</script>

<style lang="less" scoped>
.income{
  background: #ffffff;
  // border-radius: 10px;
  overflow: hidden;
  margin-bottom: 10px;
  padding: 15px;
  &-head{
    border-bottom: 1px solid #eee;
    padding: 5px 0;
    &-it{
      line-height: 1.8;
      font-weight: bold;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
  &-body{
    padding: 10px 0;
    &-it{
      line-height: 1.8;
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }
  }
}
</style>
