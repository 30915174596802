<template>
  <div class="container">
    <SimpleHeader />
    <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
      <div class="teamw">
        <van-search v-model="search" @search="onSearch" placeholder="请输入订单号或下单人名称搜索" />
        <div class="searchBlock">
          <div class="sift-box">
            <div class="sift-box--time">
              <div class="sift-item pr-20px font-w flex--c arrow" @click="timePickerShow">
                <div>{{ time.timeTitle }}</div>
              </div>
              <div class="sift-item fcontentCenter" @click="timeChangeShow('startDate')">
                <div :class="{ hasValue: time.startDate != '' }">{{ time.startDate || '开始时间' }}</div>
              </div>
              <div class="sift-item fcontentCenter" @click="timeChangeShow('endDate')">
                <div :class="{ hasValue: time.endDate != '' }">{{ time.endDate || '结束时间' }}</div>
              </div>
            </div>
          </div>
          <div class="sift-box">
            <div class="sift-box--time">
              <div class="sift-item pr-20px font-w flex--c arrow" @click="pickerShow('stateType')">
                <div class="sift-item-title">{{ stateTitle }}</div>
              </div>
              <div class="sift-item flex--c  font-w  arrow xiala" @click="pickerShow('moneyType')">
                <div class="sift-item-title">{{ moneyTitle }}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="total">
            总合计 : <span>¥ {{amount}}</span>
        </div>
        <van-list
          v-model:loading="loading"
          :finished="finished"
          :finished-text="finishedText"
          @load="getData"
          offset="100"
          loading-text="加载中..."
          :immediate-check="false"
        >
          <div class="attention">
            <IncomeItem v-for="(item, i) in list" :key="i" :item="item" />
            <Empty :image="true" :show="!loading&&list.length==0"></Empty>
          </div>
        </van-list>
        <div style="width: 100%;height: 15px;"></div>
        <div class="popBox" v-show="toast">
          <van-picker
            class="popBottom"
            title="请选择"
            :default-index="pickerIndex"
            show-toolbar
            value-key="name"
            :columns="pickerList"
            @confirm="onConfirmToast"
            @cancel="onCancelToast"
          />
        </div>

        <div class="popBox" v-show="time.timePicker">
          <van-picker
            class="popBottom"
            title="请选择"
            show-toolbar
            value-key="title"
            :default-index="time.timeIndex"
            :columns="timeTypeList"
            @confirm="onConfirm"
            @cancel="onCancel"
          />
        </div>
        <div class="popBox" v-show="time.timeWhen">
          <van-datetime-picker
            v-model="show_time"
            class="popBottom"
            type="date"
            title="选择时间"
            @confirm="onConfirmTime"
            @cancel="onCancelTime"
          />
        </div>
      </div>
    </van-pull-refresh>
  </div>
</template>
<script>
import { reactive, onMounted, inject, ref, toRefs, watch } from 'vue'
import useTime from '@/utils/time'
import { useRoute } from 'vue-router'
import { List, PullRefresh, Tab, Tabs, Toast } from 'vant'
import SimpleHeader from '@/components/SimpleHeader'
import timeTypeList from '@/utils/timeType'
import utils from '@/utils'
import IncomeItem from './components/IncomeItem.vue'
import { getCommissionDetail,getCommissionOptions,getCommissionView } from '@/api/income'
import to from 'await-to-js';
export default {
  name: 'incomeDetail',
  components: {
    [Tab.name]: Tab,
    [Tabs.name]: Tabs,
    [List.name]: List,
    [PullRefresh.name]: PullRefresh,
    SimpleHeader,
    IncomeItem
  },
  setup() {
    const _appLoadFanish = inject('appLoadFanish');
    const {
      time,
      show_time,
      onConfirmTime,
      onCancelTime,
      timeChangeShow,
      timePickerShow,
      onConfirm,
      onCancel
    } = useTime()
    const { query } = useRoute()

    let state = reactive({
      search:'',
      timeTypeList,
      limitDate: utils.computedTime('today').endDate,
      hasMore:true,
      page:1,
      size:20,
      total:-1,
      list: [],
      stateOptions:[],
      moneyOptions:[],
      error: false,
      loading: false,
      finished: false,
      finishedText:'',
      refreshing: false,
      toast:false,
      stateTitle:'全部',
      stateType:'',
      moneyTitle:'全部类型',
      moneyType:'',
      pickerList:[],
      pickerType:'',
      pickerIndex:0,
      amount:'0.00'
    })

    const onSearch = () => {
      state.page = 1
      state.list = []
      getData()
    }

    const getOptions = async () => {
      let [error,result] = await to(getCommissionOptions())
      if(error) return
      let {code,res,msg} = result
      if (code === 0) {
        state.stateOptions = res.stateOptions
        state.moneyOptions = res.moneyOptions
      }
    }

    const getData = async () => {
      if (!state.loading) {
        state.loading = true;
      }
      if (state.refreshing) {
          state.list = [];
          state.refreshing = false;
      }
      let params = {
        search:state.search,
        start: time.startDate,
        end: time.endDate,
        page:state.page,
        stateType:state.stateType,
        commissionType:state.moneyType
      }
      let [error,result] = await to(getCommissionDetail(params));
      _appLoadFanish()
      state.loading = false
      if(error) {
        state.finished = true;
        state.finishedText = ''
        return
      };
      let {code,res,msg} = result;
      if (code == 0) {
        state.list = state.page === 1 ? res.list : state.list.concat(res.list)
        state.hasMore = res.hasMore
        state.total = ~~res.totalNum
        state.finishedText = state.page === 1 && state.list.length == 0 ? '' : `当前共${state.list.length}条数据，已全部加载完`
        if (state.page === 1) {
          state.amount = res.allAmount
        }
        if (!state.hasMore) {
          state.finished = true;
        } else {
          state.page++
        }
      }

    };

    const onRefresh = () => {
      state.loading = true;
      initData()
      getData();
    };
    function pickerShow(type) {
      state.toast = true
      state.pickerType = type
      if (type == 'stateType') {
         state.pickerIndex =  state.stateOptions.findIndex(item => item.name ==  state.stateTitle)
         state.pickerList =  state.stateOptions
      } else {
         state.pickerList =  state.moneyOptions
         state.pickerIndex =  state.moneyOptions.findIndex(item => item.title ==  state.moneyTitle)
      }
    }

    async function onConfirmToast(item) {
      if (state.pickerType == 'stateType') {
        state.stateTitle = item.name
        state.stateType = item.value
      } else {
        state.moneyType = item.value
        state.moneyTitle = item.name
      }
      onSearch()
      state.pickerType = ''
      state.toast = false
    }

    function onCancelToast() {
      state.pickerType = ''
      state.toast = false
    }

    const initState = (type) => {
      let defaultState = state.stateOptions.find(item => item.value == type)
      state.stateTitle = defaultState?defaultState.name : state.stateTitle
      state.stateType = type
    }
    const initTime = (type) => {
      let tempTime = state.timeTypeList.find(v=>v.value == type)
      if (tempTime) {
        onConfirm(tempTime)
      }
    }
    watch(
      () => [time.startDate, time.endDate],
      () => {
        state.page = 1
        state.list = []
        getData()
      }
    )



    //重置
    function initData() {
      state.list = []
      state.page = 1
      state.error = false
      state.finished = false
    }

    // 刷新和加载更多结束


    onMounted(async () => {
      await getOptions()
      if (query && query.hasOwnProperty('type')) {
        initState(query.type)
      }
      if (query && query.hasOwnProperty('time')) {
        initTime(query.time)
      } else {
        await getData()
      }
    })

    return {
      ...toRefs(state),
      onSearch,
      getData,
      onRefresh,
      pickerShow,
      onConfirm,
      onCancel,
      onConfirmToast,
      onCancelToast,
      time,
      show_time,
      timeChangeShow,
      timePickerShow,
      onCancelTime,
      onConfirmTime
    }
  }
}
</script>

<style lang="less" scoped>
@import '../../common/style/searchpicker';
.tbflexBox {
  margin-top: 8px;
  .tbflexBox(17px, #ff7a5d, bold, 28px, 20px, #666666, normal, 20px, 14px);
  padding-top: 0;
  .blackColor {
    color: #333 !important;
  }
}
.container {
  background: #f8f8f8;
  min-height: 100%;
}
.total{
  background: #fff;
  line-height: 50px;
  height: 50px;
  padding-left: 15px;
  >span{
    .sc(18px,red)
  }
}
.searchBlock {
  background: #fff;
  padding: 0 15px 0;
  height: 90px;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.sift-box {
  margin-bottom: 8px;
}

.search-input {
  // width: 228px !important;
  flex: 1;
  box-sizing: border-box;
  padding-left: 33px;
  > input {
    height: 36px;
    box-sizing: border-box;
    padding: 8px 0;
  }
  &:before {
    content: '';
    position: absolute;
    left: 7px;
    top: 50%;
    transform: translateY(-50%);
    width: 24px;
    height: 24px;
    background: url('https://x.jzbull.com/x_dsx_wxapp/icons/gray-search.png') no-repeat;
    background-size: 100%;
  }
}

.yjdata {
  height: 82px;
  width: 100%;
  display: flex;
  background: #fff;
  > div {
    flex: 1;
    flex-direction: column;
  }
  .yjdata-num {
    font-size: 16px;
    color: #333333;
    line-height: 22px;
    font-weight: bold;
    margin-bottom: 4px;
  }
  .yjdata-title {
    color: #666666;
    line-height: 20px;
    font-size: 14px;
  }
}
.yjman {
  width: 345px;
  margin: 0 auto;
  height: 88px;
  padding: 15px 0;
  border-bottom: 0.5px solid #dbdbdb;
  display: flex;
  &-pic {
    width: 58px;
    height: 58px;
    border-radius: 50%;
    margin-right: 8px;
  }
  &-cont {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }
  .yjman-tel {
    color: #999999;
    line-height: 20px;
    font-size: 14px;
  }
  .yjman-nameblock {
    display: flex;
    align-items: center;
    .yjman-name {
      color: #333333;
      line-height: 22px;
      font-size: 16px;
      font-weight: bold;
    }
    .jyman-level {
      height: 21px;
      min-width: 58px;
      border: 0.5px solid #ff7a5d;
      border-radius: 5px;
      color: #ff7a5d;
      font-size: 12px;
      margin-left: 10px;
    }
  }
}
.yj-single {
  background: #fff;
  border-radius: 12px;
  overflow: hidden;
  margin-bottom: 10px;
}
.teamw,
.attention {
  background: #f8f8f8;
}
.attention {
  margin-bottom: -1px;
  padding: 15px 0;
}
</style>

<style lang="less">
.findTabs {
  background: #fff;
  .van-tab__text {
    font-size: 14px;
  }
  .van-tab {
    padding: 0 10px;
  }
  .van-tab--active .van-tab__text {
    font-size: 16px;
  }
  .van-tabs__line {
    border-radius: 2px solid #ff7a5d !important;
    bottom: 20px !important;
  }
  .van-tabs__content {
    margin-top: -4px;
  }
}
.van-list__finished-text {
  background: #f8f8f8;
}
.xiala {
  width: 228px !important;
}
.bright {
  border-right: 0.5px solid #eee;
}
.tbflexBox-one_top {
  color: #ff7a5d !important;
}
.bonus+.bonus{
  margin-top: 8px;
}
</style>
